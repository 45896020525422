import React from 'react';
import { push as BurgerMenu } from 'react-burger-menu';
import { Link } from 'gatsby';
import './burger-menu.css';

export const BurgerIcon = ({ onClick }) => {
  return (
    <button className="bm-burger-button" onClick={onClick}>
      &#9776;
    </button>
  );
};

class MenuGroup extends React.Component {
  state = { opened: false };

  toggleMenuGroup = (e) => {
    e.preventDefault();
    this.setState(({ opened }) => ({ opened: !opened }));
  };

  render() {
    const { title, link, children } = this.props;
    const openedClass = this.state.opened ? 'opened' : '';

    return (
      <li className={`menu-group ${openedClass}`}>
        <a href={link} className="menu-group--title" onClick={this.toggleMenuGroup}>
          {title}
        </a>
        {children}
      </li>
    );
  }
}

const MenuItem = ({ title, link, onClick }) => (
  <li className="menu-item">
    {!!link ? (
      <Link to={link} className="menu-item--link" onClick={onClick}>
        {title}
      </Link>
    ) : (
      <span className="menu-item--text">{title}</span>
    )}
  </li>
);

class Menu extends React.Component {
  onLinkClick = (e) => {
    if (this.props.onLinkClick) {
      this.props.onLinkClick(e);
    }
  };
  renderLinks = (links, level) => {
    return (
      <ul>
        {links.map(({ title, link, items }, index) => {
          const key = !level ? index : `${level}-${index}`;

          return typeof items !== 'undefined' ? (
            <MenuGroup {...{ title, link, key }}>
              {this.renderLinks(items, index)}
            </MenuGroup>
          ) : (
            <MenuItem {...{ title, link, key, onClick: this.onLinkClick }} />
          );
        })}
      </ul>
    );
  };

  render() {
    const { links, isOpen } = this.props;

    return (
      <BurgerMenu
        pageWrapId="page-content"
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={isOpen}
        styles={{ bmMenuWrap: { height: 'calc(100% - 60px)' } }}
      >
        {this.renderLinks(links)}
      </BurgerMenu>
    );
  }
}

export default Menu;
