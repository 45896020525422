import React, { Component } from 'react';
import Note from './note';

import './text-with-note.css';

class TextWithNote extends Component {
  state = { noteId: null };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.html !== this.props.html) {
      this.resetNote();
    }
  }

  onFootnoteLinkClick = e => {
    e.preventDefault();
    const clickedNoteId = e.target.textContent.replace(/\[(\d+)\]/, '$1');
    this.setState(({ noteId }) => ({
      noteId: !noteId || noteId !== clickedNoteId ? clickedNoteId : null
    }));
  };

  processContentHtml = () => {
    if (!this.content) {
      return;
    }
    this.footnoteLinks = Array.from(this.content.getElementsByTagName('a'));
    this.footnoteLinks.forEach(a => {
      a.addEventListener('click', this.onFootnoteLinkClick);
    });
  };

  htmlRef = c => {
    this.content = c;
    this.processContentHtml();
  };

  resetNote = () => {
    this.setState({ noteId: null });
  };

  render() {
    const { html, notes } = this.props;
    const { noteId } = this.state;
    const activeNote = !!noteId ? notes.find(note => note.id === noteId) : null;

    return (
      <article className="node">
        <div ref={this.htmlRef} dangerouslySetInnerHTML={{ __html: html }} />
        {activeNote && <Note note={activeNote} onClose={this.resetNote} />}
      </article>
    );
  }
}

export default TextWithNote;
