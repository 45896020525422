import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Menu, { BurgerIcon } from './burger-menu';

import menuLinks from '../menu.yml';
import '../css/reset.css';

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

class TemplateWrapper extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    children: PropTypes.func,
    location: PropTypes.object
  };

  state = { menuOpened: false };

  toggleMenuState = () => {
    this.setState(({ menuOpened }) => ({ menuOpened: !menuOpened }));
  };

  render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={query}
        render={(data) => (
          <>
            <Helmet
              defaultTitle={data.site.siteMetadata.title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            >
              <meta name="og:type" content="website" />
              <meta
                name="og:site_name"
                content={data.site.siteMetadata.title}
              />
              <meta
                name="description"
                content={data.site.siteMetadata.description}
              />
            </Helmet>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(100vh - 60px)',
                marginTop: '60px'
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  width: '100%',
                  height: '60px',
                  top: 0,
                  left: 0,
                  backgroundColor: '#20232a',
                  color: '#fff',
                  display: 'flex'
                }}
              >
                <BurgerIcon onClick={this.toggleMenuState} />
                <Link
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  to="/"
                >
                  {data.site.siteMetadata.title}
                </Link>
              </div>
              <Menu
                links={menuLinks}
                onLinkClick={this.toggleMenuState}
                isOpen={this.state.menuOpened}
              />
              <main id="page-content">{children}</main>
            </div>
          </>
        )}
      />
    );
  }
}

export default TemplateWrapper;
