import React, { PureComponent } from 'react';

import './note.css';

class Note extends PureComponent {
  onCloseClick = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    onClose && onClose();
  };

  render() {
    const { note } = this.props;

    if (!note) {
      return null;
    }

    return (
      <div className="note-wrapper">
        <div className="note">
          <span className="note--number">{note.id}</span>
          <span
            className="note--content"
            dangerouslySetInnerHTML={{ __html: note.html }}
          />
          <button className="note--button__close" onClick={this.onCloseClick}>
            ✕
          </button>
        </div>
      </div>
    );
  }
}

export default Note;
